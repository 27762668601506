<div class="header">
    <h1>{{this.data.heading}} Driver Cash</h1>
    <button mat-dialog-close>
        <img src="../../../../assets/images/svg/closeBtn.svg" alt="" srcset="">
    </button>
</div>
<div class="scrollHeightDialog">
    <div class="row m-0 py-4">
        <div class="col-xl-11 mx-auto" [formGroup]="driver_cash" >
            <div class="row">
               
                <div class="col-xl-12 mb-3">
                    <div class="form-group">
                        <label for="">Date of payment</label>
                        <input type="date" class="form-control"  formControlName="dateOfPayment"/>
                    </div>
                </div>
                <div class="col-xl-12 mb-3">
                    <div class="form-group">
                        <label for="">Driver Name</label>
                        <input type="text" class="form-control" formControlName="driverName"/>
                    </div>
                </div>
                <div class="col-xl-12 mb-3">
                    <div class="form-group">
                        <label for="">Amount Paid</label>
                        <input type="text" class="form-control" formControlName="amountPaid"/>
                    </div>
                </div>
                <div class="col-xl-12 mb-3">
                    <mat-radio-group color="dark" aria-label="Select an option" formControlName="payment">
                        <mat-radio-button value="UPI">UPI</mat-radio-button>
                        <mat-radio-button value="Cash">Cash</mat-radio-button>
                      </mat-radio-group>
                </div>
                <div class="col-xl-12 text-center">
                    <button class="btn btn-dark py-2 px-5"
                    mat-raised-button (click)="driverCashSubmit()" [disabled]="submitBtn">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>
